@import url("https://fonts.googleapis.com/css2?family=Audiowide&family=Roboto+Condensed:wght@300;400;700&family=Roboto+Slab:wght@400;700&display=swap");
:root {
  --Audiowide: "Audiowide", cursive;
  --RobotoCondensed: "Roboto Condensed", sans-serif;
  --RobotoSlab: "Roboto Slab", serif;
  --bodyBg: #1c2025;
  --tablesBg: #282c34;
  --itemsActiveBg: #4f5374;
  --overlay: rgba(28, 32, 37, 0.8);
  --white: #fff;
  --grey: #cacaca;
}
::-webkit-scrollbar {
  background-color: transparent;
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #535565;
  border-radius: 5px;
}
::-webkit-scrollbar-button {
  display: none;
}
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: var(--RobotoCondensed);
  background: var(--bodyBg);
  line-height: 1.3;
  color: var(--white);
}
a {
  text-decoration: none;
}
@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.form {
  .input {
    margin-bottom: 20px;
  }
  .formik-error {
    top: 0;
    left: 0;
    color: #f44336;
    margin-bottom: 30px;
    margin-top: -25px;
    @media screen and (max-width: 768px) {
      margin-bottom: 10px;
      margin-top: -10px;
    }
  }
}
svg {
  color: #fff;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 1000px var(--bodyBg) inset !important;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #fff !important;
}
.content {
  overflow-x: hidden;
  animation: appear 0.3s ease-in-out;
  .breadcrumbs {
    margin-bottom: 20px;
    a {
      color: var(--white);
    }
    .breadcrumbs__sep {
      margin: 0 10px;
    }
  }
  .content__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    @media screen and (max-width: 600px) {
      flex-direction: column;
    }
    .content__add {
      text-transform: uppercase;
      @media screen and (max-width: 600px) {
        margin-top: 20px;
        width: 100%;
      }
    }
  }
  .search {
    display: flex;
    align-items: center;
    @media screen and (max-width: 600px) {
      width: 100%;
    }
    .search__results {
      margin-left: 30px;
      display: flex;
      align-items: center;
      @media screen and (max-width: 600px) {
        margin-left: 10px;
      }
      h6,
      span {
        font-size: 18px;
        color: var(--white);
        margin: 0 2px;
      }
    }
  }
}

.matarialTable {
  //overflow-x: auto;
  background: var(--tablesBg);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  border: 1px solid #848484;
  border-radius: 5px;
}

.table {
  //overflow-x: auto;
  background: var(--tablesBg);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  border: 1px solid #848484;
  border-radius: 5px;

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    tr {
      transition: 0.3s;
      &:not(:last-child) {
        border-bottom: 1px solid #848484;
      }
      td,
      th {
        padding: 10px 2px;
        text-align: left;
        img {
          display: block;
          margin: 0 auto;
          max-width: 45px;
        }
      }
    }
    thead {
      tr {
        border-bottom: 1px solid #848484;
      }
    }
    tbody {
      tr {
        &:hover {
          background: #525465;
        }
      }
    }
  }
}
.table {
  @media screen and (max-width: 900px) {
    width: auto;
  }
  table {
    min-width: 800px;
  }
}
//.MuiPaper-root {
//  background: none !important;
//}

.formTable {
  background: var(--tablesBg);
  border-radius: 5px;
  box-shadow: 0 0 10px #000;
  padding: 20px;
  .formTable__title {
    font-size: 18px;
    color: var(--grey);
    margin-bottom: 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid var(--grey);
  }
  .formTable__formBox {
    .formTable__input {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
    .formTable__btns {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
.defaultTableWrap {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  align-items: start;
  height: 90%;
  gap: 20px;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}
.defaultTable {
  animation: appear 0.3s ease-in-out;
  background: var(--tablesBg);
  border: 1px solid #848484;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  padding: 20px;
  max-height: 100%;
  overflow-y: auto;
  &_withCheckbox {
    padding-top: 11px;
  }
  .defaultTable__title {
    border-bottom: 1px solid #848484;
    padding-bottom: 20px;
    margin-bottom: 20px;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &_withCheckbox {
      justify-content: flex-start;
      padding-bottom: 10px;
      margin-bottom: 10px;
    }
    h5 {
      font-size: 24px;
    }
    h6 {
      font-size: 18px;
      color: var(--white);
    }
  }
  .defaultTable__list {
    .defaultTable__item {
      display: flex;
      transition: 0.3s;
      padding-right: 10px;
      cursor: pointer;
      &.active {
        background: #535565;
      }
      &.selected {
        background: #4f5374;
      }
    }
  }
}
.defaultInner {
  .defaultInner__main {
    display: flex;
    align-items: center;
    @media screen and (max-width: 720px) {
      flex-direction: column;
    }
    .defaultInner__pic {
      max-width: 125px;
      width: 100%;
      margin-right: 25px;
      @media screen and (max-width: 720px) {
        margin-right: 0;
        margin-bottom: 20px;
      }
      img {
        width: 100%;
        object-fit: contain;
      }
    }
    .defaultTable {
      //max-width: 800px;
      width: 100%;
    }
    .defaultInner__initInfo {
      display: flex;
      @media screen and (max-width: 720px) {
        flex-direction: column;
      }
      p {
        margin-right: 20px;
        @media screen and (max-width: 720px) {
          margin-right: 0;
          margin-bottom: 10px;
        }
      }
      div {
        margin-left: auto;
        @media screen and (max-width: 720px) {
          margin-left: 0;
        }
        b {
          margin-right: 10px;
        }
      }
    }
  }
  .defaultInner__grid {
    display: grid;
    grid-template-columns: 1.2fr 1.8fr;
    gap: 20px;
    margin-top: 20px;
    @media screen and (max-width: 720px) {
      grid-template-columns: 1fr;
    }
    .defaultInner__table {
      overflow-x: hidden;
      table {
        min-width: 600px;
        th {
          white-space: nowrap;
        }
      }
    }
    .table {
      //margin-top: 20px;
      .table__editIcon {
        opacity: 0;
        transition: 0.3s;
      }
      tr {
        &:hover {
          .table__editIcon {
            opacity: 1;
          }
        }
      }
      th {
        padding: 5px;
      }
      td {
        &.withImage {
          display: flex;
          align-items: center;
          img {
            display: inline-block;
            margin: 0 10px 0 0;
          }
        }
      }
    }
  }
}
.accountInfos {
  .accountInfos__item {
    display: flex;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    p,
    span {
      word-break: break-word;
      font-size: 16px;
    }
    p {
      width: 40%;
      color: var(--grey);
    }
    span {
      width: 60%;
    }
  }
}
.messages {
  .MuiTypography-body1 {
    width: 100%;
  }
  .messages__list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .messages__info {
      padding: 0 2px;
      word-break: break-word;
      font-size: 14px;
    }
  }
}
.editPage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  svg {
    color: #ffffff;
  }
  .editPage__pic {
    .editPage__avatar {
      display: flex;
      flex-direction: column;
      align-items: center;
      .editPage__img {
        border: 10px solid var(--white);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        img {
          width: 100%;
        }
      }
      button {
        padding: 0;
        .editPage__label {
          padding: 6px 16px;
          cursor: pointer;
          input {
            display: none;
          }
        }
      }
    }
  }
  .editPage__form {
    .form {
      .formik-error {
        margin-bottom: 15px;
        margin-top: -15px;
      }
    }
  }
}
